import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
//import ArticlesComponent from "../../components/articles";
import BlogList from "~/components/blog-list";
import PageHeading from "~/components/styled/page-heading";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";

const IndexPage = () => {
	const data = useStaticQuery(query);

	return (
		<Layout>
			<GatsbySeo
				title="Blog"
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.in/blog`,
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/blog`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/blog`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/blog`,
				// 	},
				// ]}
			/>
			<div className="">
				<div className="">
					<PageHeading>Blog</PageHeading>
					<BlogList articles={data.allStrapiArticle.edges} />
					{/* <ArticlesComponent articles={data.allStrapiArticle.edges} /> */}
				</div>
			</div>
		</Layout>
	);
};

const query = graphql`
	query {
		allStrapiArticle {
			edges {
				node {
					strapiId
					slug
					title
					updated_at
					category {
						name
					}
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 800, height: 500)
							}
						}
					}
					author {
						username
						picture {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 30, height: 30)
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default IndexPage;
