import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Moment from "react-moment";
import Image from "~/components/image";
import Card from "~/components/styled/card";

const BlogList = ({ articles }) => (
	<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-20">
		{articles.map(({ node }) => {
			return (
				<Card key={node.id}>
					<Link to={`/blog/${node.slug}`}>
						<Image
							alt="Category Image"
							className="rounded-t-md transform border-gray-200	 border-b"
							image={node.image}
						/>

						<div className="px-4 py-2 ">
							<div className="mb-0.5">
								<p className="font-medium capitalize truncate hover:text-primary">
									{node.title}
								</p>
							</div>
							<div className="flex mb-4">
								<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
									{node.category.name}
								</p>
							</div>
							<div>
								<p className="text-xs text-gray-500 capitalize">
									{node.author.username}
									{" • "}
									<Moment format="MMM Do YYYY">{node.updated_at}</Moment>
								</p>
							</div>
						</div>
					</Link>
				</Card>
			);
		})}
	</div>
);

BlogList.propTypes = {
	articles: PropTypes.array,
};

export default BlogList;
